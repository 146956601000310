import Vue from "vue";
import VueRouter from "vue-router";
import pasien from "./pasien";
import users from "./users";

Vue.use(VueRouter);

const routes = [
  ...pasien,
  ...users,
  {
    path: "/login",
    component: () => import("../views/Login.vue"),
    meta: {
      requireAuth: false,
      layout: "full-page",
    },
  },
];

const router = new VueRouter({
  routes,
});

// Router guard login
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (localStorage.getItem("current_user") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
