const pasien = [
  {
    path: "/",
    component: () => import("../views/pasien/List.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/add",
    name: "Add",
    component: () => import("../views/pasien/Add.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/show",
    name: "Show",
    component: () => import("../views/pasien/Show.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/edit/:id",
    name: "Edit",
    component: () => import("../views/pasien/Edit.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/qr_card/:tokenId",
    name: "QrCard",
    component: () => import("../views/pasien/QrCard.vue"),
    meta: {
      requireAuth: false,
      layout: "full-page",
    },
  },
  {
    path: "/print",
    name: "printForm",
    component: () => import("../views/pasien/PrintFormV2.vue"),
    meta: {
      requireAuth: true,
      layout: "full-page",
    },
  },
];

export default pasien;
