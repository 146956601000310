import Vue from "vue";
import Vuex from "vuex";
import users from "./users";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pasienList: [],
    pasien: "",
    selectedPasiens: [],
    error: false,
    errorMessage: "",
    currentUser: {},
    accessToken: "",
    flashMessage: "",
  },
  getters: {
    pasienList: (state) => {
      return state.pasienList;
    },
    pasien: (state) => {
      return state.pasien;
    },
    pasienListTobePrinted: (state) => {
      return state.selectedPasiens;
    },
    currentUser: (state) => {
      return state.currentUser;
    },
    flashMessage: (state) => {
      return state.flashMessage;
    },
    pasienById: (state) => (id) => {
      return state.pasienList.filter((pasien) => pasien.id === id)[0];
    },
  },
  mutations: {
    SET_PASIENLIST(state, pasienList) {
      state.pasienList = pasienList;
    },
    SET_PASIEN_LIST_FOR_PRINT(state, data) {
      state.selectedPasiens = data;
    },
    SET_PASIEN(state, pasien) {
      state.pasien = pasien;
    },
    SET_ERROR(state, { error, errorMessage }) {
      state.error = error;
      state.errorMessage = errorMessage;
    },
    DELETE_PASIEN(state, id) {
      state.pasienList = state.pasienList.filter((pasien) => pasien.id != id);
    },
    SET_CURRENT_USER(state, user) {
      state.currentUser = user;
      state.accessToken = user.token;
    },
  },
  actions: {
    getPasienList({ commit }) {
      Vue.axios
        .get("/api/Pasien")
        .then((response) => {
          commit("SET_PASIENLIST", response.data);
        })
        .catch((error) => {
          commit("SET_ERROR", {
            error: true,
            errorMessage: error.response.data,
          });

          // If unauthorized remove current user token
          if (error.response.status == 401)
            localStorage.removeItem("current_user");
        });
    },
    /* eslint-disable no-unused-vars */
    async addPasien({ commit }, dataPasien) {
      await Vue.axios.post("/api/Pasien", dataPasien);
    },
    async updatePasien({ commit }, { id, pasien }) {
      await Vue.axios.put(`/api/Pasien/${id}`, pasien);
    },
    getSelectedPasien({ commit }, pasien) {
      commit("SET_PASIEN", pasien);
    },
    getPasien({ commit }, id) {
      Vue.axios
        .get(`/api/Pasien/${id}`)
        .then((response) => {
          commit("SET_PASIEN", response.data);
        })
        .catch((error) => {
          commit("SET_ERROR", { error: true, errorMessage: error.message });

          // If unauthorized remove current user token
          if (error.response.status == 401)
            localStorage.removeItem("current_user");
        });
    },
    getPasienForPrint({ commit }, data) {
      commit("SET_PASIEN_LIST_FOR_PRINT", data);
    },
    async deletePasien({ commit }, id) {
      await Vue.axios.delete(`/api/Pasien/${id}`);
      commit("DELETE_PASIEN", id);
    },
    setCurrentUser({ commit }, user) {
      commit("SET_CURRENT_USER", user);
    },
  },
  modules: {
    users: users,
  },
});
