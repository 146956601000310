import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import Default from "./views/layout/Default.vue";
import FullPage from "./views/layout/FullPage.vue";

import VueParticles from "vue-particles";

Vue.config.productionTip = false;

Vue.component("default-layout", Default);
Vue.component("full-page-layout", FullPage);

Vue.use(VueParticles);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
