<template>
  <v-app>
    <component :is="layout"> </component>
  </v-app>
</template>

<script>
const default_layout = "default";

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
};
</script>

<style>
@media print {
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  header {
    display: none !important;
  }
  nav {
    display: none !important;
  }
}
</style>
