<template>
  <v-app id="inspire">
    <v-navigation-drawer app v-model="drawer" dark class="nav-bgcolor">
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar size="96">
            <v-img src="@/assets/logo_kotak.jpg"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item link v-bind="attrs" v-on="on">
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  {{ `${currentUser.firstName} ${currentUser.lastName}` }}
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  currentUser.email
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-card class="mx-auto" max-width="300" tile>
            <v-list flat>
              <v-subheader>USER MENU</v-subheader>
              <v-list-item-group color="primary">
                <v-list-item
                  :to="{ name: 'EditUser', params: { id: currentUser.id } }"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-account-edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Edit Profil</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="logout">
                  <v-list-item-icon>
                    <v-icon>mdi-power</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Keluar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item
          link
          v-for="item in navList"
          :key="item.title"
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app class="red darken-1" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Registrasi Pasien</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    navList: [
      // { title: "Beranda", icon: "mdi-home", link: "/" },
      {
        title: "Daftar Registrasi",
        icon: "mdi-account-details",
        link: "/",
      },
      {
        title: "User Management",
        icon: "mdi-account-group",
        link: "/user_management",
      },
    ],
  }),
  computed: {
    currentUser() {
      return JSON.parse(localStorage.getItem("current_user")) || {};
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("current_user");
      this.axios.defaults.headers.common["Authorization"] = "";
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.nav-bgcolor {
  background-color: #2d3541 !important;
}
</style>
