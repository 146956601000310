import Vue from "vue";

const users = {
  state: () => ({
    usersList: [],
    user: {},
    error: "",
  }),
  mutations: {
    SET_USERSLIST(state, users) {
      state.usersList = users;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    DELETE_USER(state, id) {
      state.usersList = state.usersList.filter((user) => user.id != id);
    },
  },
  actions: {
    getUsersList({ commit }) {
      Vue.axios
        .get("/api/users")
        .then((response) => {
          commit("SET_USERSLIST", response.data);
        })
        .catch((error) => {
          commit("SET_ERROR", {
            error: true,
            errorMessage: error.response.data,
          });

          // If unauthorized remove current user token
          if (error.response.status == 401)
            localStorage.removeItem("current_user");
        });
    },
    getUser({ commit }, user) {
      commit("SET_USER", user);
    },
    /* eslint-disable no-unused-vars */
    async addUser({ commit }, dataUser) {
      await Vue.axios.post("/api/users", dataUser);
    },
    async editUser({ commit }, { id, dataUser }) {
      await Vue.axios.put(`/api/users/${id}`, dataUser);
    },
    async deleteUser({ commit }, id) {
      await Vue.axios.delete(`/api/users/${id}`);
      commit("DELETE_USER", id);
    },
  },
  getters: {
    usersList: (state) => {
      return state.usersList;
    },
    user: (state) => {
      return state.user;
    },
    userById: (state) => (id) => {
      return state.usersList.filter((user) => user.id === id)[0];
    },
  },
};

export default users;
