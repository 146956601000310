const users = [
  {
    path: "/user_management",
    component: () => import("../views/users/List.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/user_management/add",
    name: "AddUser",
    component: () => import("../views/users/Add.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/user_management/show",
    name: "ShowUser",
    component: () => import("../views/users/Show.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/user_management/edit/:id",
    name: "EditUser",
    component: () => import("../views/users/Edit.vue"),
    meta: {
      requireAuth: true,
    },
  },
];

export default users;
